import { useMemo } from "react";
import SubmitEditsForm from "../../../../../../../components/forms/SubmitEditsForm";
import Input from "../../../../../../../components/Input";
import Select from "../../../../../../../components/Select";
import GenericText from "../../../../../../../components/texts/GenericText";
import useVerifyObjectEqual from "../../../../../../../hooks/useVerifyObjectEqual";
import { integrationsType } from "../mockFieldIntegration";

interface IGenericContainer {
  integration: Record<string, any>;
  initialObject: Record<string, any>;
  selectIntegration: string;
  handleChangeIntegration: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string
  ) => void;
  handleConfirm: () => void;
  setSelectIntegration: (value: string) => void;
  loading: boolean;
}

const GenericContainer = ({
  integration,
  initialObject,
  handleChangeIntegration,
  handleConfirm,
  setSelectIntegration,
  selectIntegration,
  loading,
}: IGenericContainer) => {
  const disableButton = useVerifyObjectEqual({
    initialObject: initialObject,
    actualObject: integration,
  });

  const formatLabel = (key: string) => {
    return key
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const ignoredKeys = ["createdAt", "updatedAt", "type", "id"];

  const allFieldsFilled = Object.keys(integration || {})
    .filter((key) => !ignoredKeys.includes(key))
    .every((key) => integration[key] !== undefined && integration[key] !== "");

  const isButtonDisabled = useMemo(() => {
    return disableButton || !allFieldsFilled;
  }, [disableButton, allFieldsFilled]);

  const valueSelect = useMemo(() => {
    return integrationsType.find((i) => i.name === selectIntegration);
  }, [selectIntegration]);

  return (
    <SubmitEditsForm
      title={"Integração"}
      buttonProps={{
        disabled: isButtonDisabled || loading,
        onClick: handleConfirm,
        loading: loading,
      }}
      renderInsideComponents={() => (
        <>
          {valueSelect?.name === "ixc" && (
            <GenericText size={"h7"} margin="10px 0" >
              {`Certifique-se de que o cliente libere o nosso IP para que a integração funcione corretamente. Segue o IP:`}
              <GenericText size={"h4"} color="red" alignSelf="flex-start">
                167.99.233.34
              </GenericText>
            </GenericText>
          )}
          <Select
            data={integrationsType}
            value={valueSelect}
            label="Tipo de integração"
            placeholder="Selecione o tipo de integração"
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.id}
            onChange={(e: any) => setSelectIntegration(e.name)}
          />
          {Object.keys(integration || {})
            .filter((key) => !ignoredKeys.includes(key))
            .map((key) => (
              <Input
                key={key}
                name={key}
                type="text"
                width={"100%"}
                disabled={key === "id"}
                label={formatLabel(key)}
                margin={"5px 0px"}
                value={integration?.[key] || ""}
                onChange={(e) => handleChangeIntegration(e, key)}
              />
            ))}
        </>
      )}
    />
  );
};

export default GenericContainer;
