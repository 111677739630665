/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import integrationService from "../../../../../services/api/integration/integrationService";
import GenericContainer from "./integration/GenericContainer";
import { useMutation, useQuery } from "@tanstack/react-query";
import { mockFieldIntegration } from "./integration/mockFieldIntegration";
import { IPatchIntegration } from "../../../../../services/api/integration/interface";
import { toast } from "react-toastify";

const BasicIntegration = ({ id }: { id: number }) => {
  const [integration, setIntegration] = useState<Record<string, any>>({});
  const [initialObject, setInitialObject] = useState<Record<string, any>>({});
  const [selectIntegration, setSelectIntegration] = useState("");
  const [isIntegration, setIsIntegration] = useState(false);

  const { data } = useQuery({
    queryKey: ["get-integration", id],
    queryFn: () => integrationService.getIntegration(id),
    retry: false,
  });

  const { mutate, isPending: loadingPost } = useMutation({
    mutationKey: ["post-integration", id],
    mutationFn: integrationService.postIntegration,
    onSuccess: () => {
      toast.success("Integração criada com sucesso");
      setInitialObject(integration);
      setIsIntegration(true);
    },
    onError: (error) => {
      toast.error("Erro ao criar integração");
    },
  });

  const { mutate: update, isPending: loadingPut } = useMutation({
    mutationKey: ["patch-integration", id],
    mutationFn: (body: IPatchIntegration) =>
      integrationService.patchIntegration(id, body),
    onSuccess: () => {
      toast.success("Integração alterada com sucesso");
      setInitialObject(integration);
      setIsIntegration(true);
    },
    onError: (error) => {
      toast.error("Erro ao alterar integração");
    },
  });

  const handleConfirm = () => {
    const { type, ...rest } = integration;
    if (isIntegration) {
      const object = {
        integrationType: data?.integration.type,
        integrationData: rest,
      };
      update(object as any);
      return;
    }
    const object = {
      companyId: id,
      integrationType: data?.integration.type || integration.type,
      data: rest,
    };
    mutate(object as any);
  };

  const handleChangeIntegration = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string
  ) => {
    const value = e.target.value === "" ? undefined : e.target.value;

    setIntegration((prev) => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    const updateIntegration = (integrationData: Record<string, any>) => {
      setIntegration(integrationData);
      setInitialObject(integrationData);
    };

    const handleIntegrationUpdate = () => {
      if (data?.integration?.data) {
        updateIntegration(data.integration.data);
        setSelectIntegration(data.integration.type);
        setIsIntegration(true);
      }
    };

    const handleSelectedIntegration = () => {
      const selectedIntegration = mockFieldIntegration.find(
        (item: any) => item.type === selectIntegration
      );

      if (!selectedIntegration) return;

      let newIntegrationData: Record<string, any> = {
        ...selectedIntegration,
        id,
      };

      if (selectIntegration === data?.integration?.type) {
        setIsIntegration(true);
        updateIntegration(data.integration.data);
      } else {
        setIsIntegration(!!data?.integration?.data);
        updateIntegration(newIntegrationData);
      }
    };

    if (data?.integration?.data && !selectIntegration.length) {
      handleIntegrationUpdate();
    } else if (selectIntegration.length) {
      handleSelectedIntegration();
    }
  }, [data?.integration?.data, selectIntegration, mockFieldIntegration, id]);

  const isLoading = loadingPut || loadingPost;

  const RenderIntegration = useMemo(() => {
    return (
      <GenericContainer
        loading={isLoading}
        handleChangeIntegration={handleChangeIntegration}
        initialObject={initialObject}
        integration={integration}
        handleConfirm={handleConfirm}
        setSelectIntegration={setSelectIntegration}
        selectIntegration={selectIntegration}
      />
    );
  }, [data, initialObject, integration, selectIntegration, isLoading]);

  return RenderIntegration;
};

export default BasicIntegration;
