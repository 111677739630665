export const integrationsType = [
  {
    name: "hubSoft",
    id: 1,
  },
  {
    name: "sgp",
    id: 2,
  },
  {
    name: "ispfy",
    id: 3,
  },
  {
    name: "ixc",
    id: 4,
  },
  {
    name: "radiusNet",
    id: 5,
  },
  {
    name: "topSapp",
    id: 6,
  },
];

export const mockFieldIntegration: Record<string, any> = [
  {
    type: "hubSoft",
    id: undefined,
    clientId: undefined,
    clientSecret: undefined,
    password: undefined,
    token: undefined,
    url: undefined,
    username: undefined,
  },
  {
    type: "sgp",
    id: undefined,
    app: undefined,
    token: undefined,
    url: undefined,
  },
  {
    type: "ispfy",
    id: undefined,
    token: undefined,
    url: undefined,
  },
  {
    type: "ixc",
    id: undefined,
    token: undefined,
    url: undefined,
  },
  {
    type: "radiusNet",
    id: undefined,
    token: undefined,
    url: undefined,
  },
  {
    type: "topSapp",
    id: undefined,
    user: undefined,
    password: undefined,
    identifier: undefined,
    url: undefined,
  },
];
