/* eslint-disable import/no-anonymous-default-export */
import getIntegration from "./getIntegration";
import patchIntegration from "./patchIntegration";
import postIntegration from "./postIntegration";

class Service {
  getIntegration = getIntegration;
  postIntegration = postIntegration;
  patchIntegration = patchIntegration;
}
export default new Service();
